







import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'ProjectIndex' })
export default class Index extends Vue {}
